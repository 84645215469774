import React, { useEffect, useState } from "react";
import { FaCheck, FaHome, FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import CureBayLogo from "../assets/CureBayLogo.svg";

const OrderApproval = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("orderId");
  const API_URL = process.env.REACT_APP_API_URL;

  const [orderData, setOrderData] = useState(null);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [isApproved, setIsApproved] = useState(false);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await fetch(`${API_URL}order/getOrderDetails?orderId=${orderId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        if (data.responseCode === "200") {
          setOrderData(data.successObject);
        } else {
          console.error("Failed to fetch data, Response Code:", data.responseCode);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  const handleApprove = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}order/updateOrderStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          orderId,
          orderStatus: 1,
          userCode: "L3",
          partnerCode: orderData.partnerCode,
        }),
      });

      const data = await response.json();
      if (data.responseCode === "200") {
        toast.success("Order approval successful");
        setApprovalStatus("Approved");
        setIsApproved(true);
      } else {
        toast.error("Failed to approve order: " + data.responseMessage);
      }
    } catch (error) {
      console.error("Error approving order:", error);
      alert("An error occurred while approving.");
    } finally {
      setLoading(false);
    }
  };

  const handleRejectSubmit = async () => {
    if (!rejectionReason.trim()) {
      alert("Please enter a reason for rejection.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${API_URL}order/updateOrderStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          orderId,
          orderStatus: 14,
          level3RejectRemarks: rejectionReason,
          userCode: "L3",
          partnerCode: orderData.partnerCode,
        }),
      });

      const data = await response.json();
      if (data.responseCode === "200") {
        toast.success("Order rejection successful");
        setApprovalStatus("Rejected");
        setIsApproved(true);
        setIsRejectModalOpen(false);
      } else {
        toast.error("Failed to reject order: " + data.responseMessage);
      }
    } catch (error) {
      console.error("Error rejecting order:", error);
      alert("An error occurred while rejecting.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col items-center">
      <div className="bg-[#004171] text-white p-6 text-center w-full shadow-md">
        <img src={CureBayLogo} alt="CureBay Logo" className="h-12 sm:h-16 mx-auto" />
      </div>
      {loading ? (
        <div className="flex justify-center items-center min-h-[50vh]">
          <h2 className="text-lg font-semibold text-gray-700">Loading...</h2>
        </div>
      ) : !orderData ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
          <div className="bg-white shadow-lg rounded-lg p-6 text-center">
            <h2 className="text-lg font-semibold text-gray-700">
              No partner details found!
            </h2>
          </div>
        </div>
      ) : isApproved ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
          <div className="bg-white shadow-lg rounded-lg p-6 text-center">
            <h2 className="text-lg font-semibold text-gray-700">
              {approvalStatus === "Approved"
                ? "Approval successful! 🎉"
                : "Rejected successfully! 🎉"}
            </h2>
          </div>
        </div>
      ) : orderData?.orderStatus != 13 ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
          <div className="bg-white shadow-lg rounded-lg p-6 text-center">
            <h2 className="text-lg font-semibold text-gray-700">
              Approval is already processed!
            </h2>
          </div>
        </div>
      ) : (
        <div className="max-w-lg w-full px-4 sm:px-6 mt-8">
          {orderData && (
            <div className="bg-white shadow-lg rounded-xl p-6 transition transform hover:scale-105 hover:shadow-xl duration-300">
              <div className="flex justify-between items-center border-b pb-4 mb-4">
                <div className="flex items-center gap-3">
                  <FaHome className="text-blue-600" size={28} />
                  <h2 className="font-bold text-lg text-gray-800">
                    {orderData.partnerName || "N/A"}
                  </h2>
                </div>
              </div>
              <p className="text-orange-400 text-sm font-medium bg-orange-100 p-2 rounded-md text-center">
                {approvalStatus || "Waiting for Approval"}
              </p>
              <div className="mt-4 text-md text-gray-700 space-y-2 bg-gray-50 p-4 rounded-lg">
                <p className="text-sm">
                  <span className="font-semibold">Order ID:</span> {orderData.orderId || "N/A"}
                </p>
                <p className="text-sm">
                  <span className="font-semibold">Approval Reason:</span> {orderData.approvalReason || "N/A"}
                </p>
                <p className="text-sm">
                  <span className="font-semibold">Order Amount:</span> ₹{orderData.totalAmount || "N/A"}
                </p>
                {/* <p className="text-sm">
                  <span className="font-semibold">Discount:</span> {orderData.discountPercent || "0"}%
                </p> */}
              </div>
              <div className="flex justify-between mt-6">
                <button
                  onClick={handleApprove}
                  disabled={loading}
                  className="flex items-center justify-center gap-2 bg-green-400 text-white font-semibold px-2 py-3 rounded-lg shadow-lg hover:bg-green-600 w-full mr-3 transform hover:scale-105 duration-300"
                >
                  <FaCheck size={15} /> {loading ? "Approving..." : "Approve"}
                </button>
                <button
                  onClick={() => setIsRejectModalOpen(true)}
                  disabled={loading}
                  className="flex items-center justify-center gap-2 bg-red-400 text-white font-semibold px-2 py-3 rounded-lg shadow-lg hover:bg-red-600 w-full ml-3 transform hover:scale-105 duration-300"
                >
                  <FaTimes size={15} /> Reject
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {isRejectModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-semibold text-gray-700 mb-4">Enter Rejection Reason</h2>
            <textarea
              className="w-full p-3 border rounded-md text-gray-700"
              rows="4"
              placeholder="Type your reason here..."
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
            <div className="flex justify-between mt-4">
              <button onClick={() => setIsRejectModalOpen(false)} className="bg-red-400 text-white px-4 py-2 rounded-lg">Cancel</button>
              <button onClick={handleRejectSubmit} className="bg-green-400 text-white px-4 py-2 rounded-lg hover:bg-green-600">Submit</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderApproval;
