import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import DeleteIcon from "../../assets/DeleteIcon.svg";

const Ongoingedit = ({ order, setActiveTab, setVisibleEdit }) => {
  const [orderItems, setOrderItems] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [error, setError] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const [initialQuantities, setInitialQuantities] = useState({});
  const [discountPercent, setDiscountPercent] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const [orderRejected, setOrderRejected] = useState(false);

  // Function to get SLA text
  const getSLAText = (sla) => {
    const slaTextMap = {
      1: "0 -12 Hours",
      2: "12-24 Hours",
      3: "24-36 Hours",
      4: "36-48 Hours",
      5: "48-60 Hours",
      6: "60-72 Hours",
      7: "72 Hrs-1 Week",
      8: "Can not be supplied",
      9: "Alternate Medidicine",
    };
    return slaTextMap[sla] || "";
  };

  const fetchOrderItems = async () => {
    try {
      const payload = {
        partnerCode: order.partnerCode,
        orderId: order.orderId,
      };

      const response = await fetch(`${API_URL}order/getOrderItemDetails`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      console.log(
        data.successObject.discountPercent,
        data.successList,
        "dxcfgvh"
      );
      if (data.successList) {
        setOrderItems(data.successList);
        setDiscountPercent(data.successObject.discountPercent);
        setDiscountAmount(data.successObject.discountAmount);
        calculateSubtotal(data.successList);
        initializeQuantities(data.successList);
      } else {
        setError("Failed to fetch order item details.");
      }
    } catch (err) {
      console.error(err);
      setError("An error occurred while fetching order item details.");
    }
  };

  useEffect(() => {
    fetchOrderItems();
  }, []);

  const initializeQuantities = (items) => {
    const initialQuantitiesMap = {};
    items.forEach((item, index) => {
      initialQuantitiesMap[index] = item.modifiedQuantity;
    });
    setInitialQuantities(initialQuantitiesMap);
  };

  const updateQuantity = (index, newQuantity) => {
    const maxQuantity = initialQuantities[index];

    if (newQuantity < 1 || newQuantity > maxQuantity) return;

    const updatedItems = [...orderItems];
    updatedItems[index].modifiedQuantity = newQuantity;
    updatedItems[index].modifiedTotalAmount =
      updatedItems[index].modifiedPrice * newQuantity;
    setOrderItems(updatedItems);

    calculateSubtotal(updatedItems);
  };

  const calculateSubtotal = (items) => {
    const newSubtotal = items
      .filter((item) => item.sla !== 9)
      .reduce((sum, item) => sum + item.modifiedTotalAmount, 0);

    setSubtotal(newSubtotal);
  };

  const deleteItem = (currentItemIndex, id) => {
    const updatedItems = orderItems.filter((item) => item.id !== id);
    setOrderItems(updatedItems);
    calculateSubtotal(updatedItems);
  };

  useEffect(() => {
    fetchOrderItems();
  }, []);

  useEffect(() => {
    calculateTotal(subtotal, discountPercent);
  }, [subtotal, discountPercent]);

  const calculateTotal = (subtotal, discountPercent) => {
    const discountAmount = (subtotal * discountPercent) / 100;
    const total = subtotal - discountAmount;
    setTotalAmount(total);
  };

  // Handle discount input change
  const handleDiscountChange = (e) => {
    const newDiscount = e.target.value;
    if (newDiscount >= 0 && newDiscount <= 100) {
      setDiscountPercent(newDiscount);
      calculateTotal(subtotal, newDiscount); // Calculate total after discount change
    }
  };

  // Handle update order (Proceed)
  const handleUpdate = async () => {
    const userCode = localStorage.getItem("userCode");

    setLoading(true);

    const createPayload = () => {
      // Map through order items to construct drugOrderDetailsList
      const drugOrderDetailsList = orderItems?.map((item) => {
        const quantity = Number(item?.modifiedQuantity);
        const buy = Number(item?.modifiedBuyQuantity)
          ? Number(item?.modifiedBuyQuantity)
          : 1;
        const getFree = Number(item?.modifiedGetQuantity)
          ? Number(item?.modifiedGetQuantity)
          : 0;

        const quotient = Math.floor(quantity / buy);
        const modifiedFreeQuantity = quotient * getFree;
        const totalmodifiedquantity =
          modifiedFreeQuantity + item?.modifiedQuantity;

        // Construct each drugDetails object
        return {
          ...item,
          modifiedTotalQuantity: totalmodifiedquantity,
          modifiedFreeQuantity: modifiedFreeQuantity,
          orderId: order.orderId,
          itemStatus: 1,
          discountAmount: "", // Placeholder values as in mobile code
          discountPercent: "",
          drugCategory: item?.drugCategory,
        };
      });

      // Construct the final payload with the drugOrderDetailsList
      const payload = {
        partnerCode: order.partnerCode,
        userCode: userCode, // Replace with actual userCode or get from context/state
        actualAmount: subtotal,
        discountAmount: discountPercent
          ? (subtotal * discountPercent) / 100
          : 0,
        discountPercent: discountPercent,
        totalAmount: totalAmount,
        drugOrderDetailsList: drugOrderDetailsList,
        id: order.id,
        orderStatus: "4",
        orderId: order.orderId,
        sourceChannel: "Web",
      };

      return payload;
    };

    const payload = createPayload(); // Generate payload

    console.log(payload, "pay;aoaddd");

    try {
      const response = await fetch(`${API_URL}order/confirmOrders`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      console.log(data);

      if (data.responseCode === "200") {
        setOrderSuccess(true);

        setVisibleEdit(false);

        setActiveTab(1);
        localStorage.setItem("activeTab", "1");

        // Add a small delay and reload the page
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setError("Failed to confirm the order.");
      }
    } catch (error) {
      setError("An error occurred while confirming the order.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Handle reject order
  const handleReject = async () => {
    setLoading(true);
    const payload = {
      partnerCode: order.partnerCode,
      orderId: order.orderId,
      orderStatus: "3",
      sourceChannel: "Web",
    };

    try {
      const response = await fetch(`${API_URL}order/updateOrderStatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      if (data.responseCode === "200") {
        setOrderRejected(true);

        setVisibleEdit(false);

        setActiveTab(1);
        localStorage.setItem("activeTab", "1");

        // Add a small delay and reload the page
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setError("Failed to reject the order.");
      }
    } catch (error) {
      setError("An error occurred while rejecting the order.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full flex flex-col h-full">
      <h2 className="text-xl font-bold mb-4">Order Details</h2>
      {error && <p>{error}</p>}
      <div className="flex-grow overflow-y-auto">
        <Table aria-label="added medicines" className="w-full">
          <TableHead className="bg-[#FAFAFA]">
            <TableRow>
              {/* Table Headers */}
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Medicine Name
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Type
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Original Unit Price(₹)
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Enquired Scheme
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Enquired Total Quantity
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Revised Unit Price(₹)
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Approved Scheme
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Modified Total Quantity
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Modified Total Amount(₹)
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                SLA
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Quantity
              </TableCell>
              <TableCell
                style={{ fontSize: "16px", fontWeight: 600, color: "#004172" }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderItems.map((medicine, index) => {
              const slaText = getSLAText(medicine?.sla);
              const quantity = Number(medicine?.modifiedQuantity);
              const buy = Number(medicine?.modifiedBuyQuantity) || 1;
              const getFree = Number(medicine?.modifiedGetQuantity) || 0;
              const quotient = Math.floor(quantity / buy);
              const totalQuantity = quotient * getFree + quantity;

              return (
                <TableRow key={index}>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.alternateMedicineName ? (
                      <>
                        <span style={{ color: "black" }}>
                          {medicine.drugName}
                        </span>
                        <br />
                        <span style={{ color: "orange", fontWeight: "normal" }}>
                          (Alternative Medicine:{" "}
                          {medicine.alternateMedicineName})
                        </span>
                      </>
                    ) : (
                      <span style={{ color: "black" }}>
                        {medicine.drugName}
                      </span>
                    )}
                  </TableCell>

                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.drugTypeOfSell}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.unitPrice}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.buyQuantity
                      ? `Buy ${medicine.buyQuantity} Get ${medicine.getQuantity}`
                      : "No offer"}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.quantity}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.modifiedPrice}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine.modifiedBuyQuantity
                      ? `Buy ${medicine.modifiedBuyQuantity} Get ${medicine.modifiedGetQuantity}`
                      : "No offer"}
                  </TableCell>
                  <TableCell
                    className="px-4 py-2 text-sm"
                    style={{ color: "#22c55e" }}
                  >
                    {totalQuantity}{" "}
                    {medicine.modifiedBuyQuantity
                      ? `(${medicine.modifiedQuantity} + ${
                          quotient * getFree
                        } Free)`
                      : ""}
                  </TableCell>

                  <TableCell className="px-4 py-2 text-sm">
                    {Number(medicine.modifiedTotalAmount).toFixed(2)}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">{slaText}</TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine?.sla == 9 ? (
                      <>{medicine?.modifiedQuantity}</>
                    ) : (
                      <div className="flex items-center space-x-2">
                        <button
                          className="border px-2 py-1 text-sm"
                          onClick={() =>
                            updateQuantity(index, medicine.modifiedQuantity - 1)
                          }
                        >
                          -
                        </button>
                        <input
                          type="text"
                          value={medicine.modifiedQuantity}
                          className="w-8 text-center border"
                        />

                        <button
                          className="border px-2 py-1 text-sm"
                          onClick={() =>
                            updateQuantity(index, medicine.modifiedQuantity + 1)
                          }
                        >
                          +
                        </button>
                      </div>
                    )}
                  </TableCell>
                  <TableCell className="px-4 py-2 text-sm">
                    {medicine?.sla == 9 ? (
                      ""
                    ) : (
                      <button
                        className="text-red-500"
                        onClick={() => deleteItem(index, medicine.id)}
                      >
                        <img
                          src={DeleteIcon}
                          alt="Delete"
                          width={20}
                          height={20}
                        />
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div className="flex justify-end mt-4">
        <div className="bg-[#EEF5FD8A] p-4 rounded-lg w-1/3">
          <div className="flex justify-between">
            <span className="text-sm font-medium">Subtotalllllll (₹)</span>
            <span className="text-sm font-medium">{subtotal.toFixed(2)}</span>
          </div>
          <div className="flex justify-between mt-1">
            <span className="text-sm font-medium">Discount (%)</span>
            <p>{discountPercent}</p>
          </div>
          <div className="flex justify-between mt-1">
            <span className="text-sm font-medium">Discount Amount (₹)</span>
            <p>{discountAmount}</p>
          </div>
          <hr className="my-2" />
          <div className="flex justify-between">
            <span className="text-lg font-bold">Total (₹)</span>
            <span className="text-lg font-bold">{totalAmount.toFixed(2)}</span>
          </div>
          <div className="mt-2 flex justify-between">
            <button
              onClick={handleReject}
              className="bg-[#ff4d4d] text-white py-2 px-4 rounded-full"
            >
              Reject
            </button>
            <button
              onClick={handleUpdate}
              className="bg-[#004172] text-white py-2 px-4 rounded-full"
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ongoingedit;
